import { useEffect, useState } from "react";

const useFetch = (route, page="") => {

    const [data,setData]=useState(null);
    const [statusCode,setStatusCode]=useState(null);
    const [isPending, setIsPending] = useState(true);
    const [errorMsg, setErrorMsg] = useState(null);
    const backend=process.env.REACT_APP_BACKEND_URL;
useEffect(()=>{
setIsPending(true)
    const abortCont = new AbortController();


fetch(backend+"/api/"+route,{

    signal: abortCont.signal,
    headers: {
      "Content-Type": "application/json",
    },

})
.then((res) => {
    if (!res.ok) {
      throw Error("could not fetch the data");
    }
    return res.json();
  })
  .then((results) => {
    setStatusCode(results.statusCode)
    if (page == "" || page == "0") {
      setData(results.data);
    } else {
      Object.values(results.data).map((obj) => {
        setData((oldArray) => [...oldArray, obj]);
      });
    }
    setIsPending(false);
    setErrorMsg(null);
  })
  .catch((err) => {
    if (err.name === "AbortError") {
      console.log(err.message);
    } else {
      setErrorMsg(err.message);
      setIsPending(false);
    }
  });

  return () => abortCont.abort();

},[route, page])

    return {data, statusCode,isPending, errorMsg };
}
 
export default useFetch;