import { useParams } from "react-router-dom";
import useFetch from "../hooks/useFetch";
import { Container, Flex, Image, Title } from "@mantine/core";
import { useNavigate } from "react-router-dom/dist";
import MyLoader from "../components/MyLoader";

const Projects = () => {
  const { id } = useParams();
  const { data } = useFetch("category/" + id);
  const navigate=useNavigate();

  const backend = process.env.REACT_APP_BACKEND_URL;


  function goToProject(id,title){

navigate('/project/'+id+'/'+title)
  }
  return (
    <>
      {data ? (
        <div>
          <Title
            order={1}
            weight={300}
            align="center"
            style={{ paddingTop: "140px", letterSpacing: "4px" }}
          >
            {data.title}
          </Title>
          <Container>
            {data.projects !== [] ? (
              <>
                {data.projects.map((project, index) => (
                  <div key={index} style={{ padding: "20px 0px" }}>
                    <Title align="center" weight={300} order={4}>
                      {project.title}
                    </Title>
                    <div
                      className="project-card"
                      width={"100%"}
                      style={{
                        backgroundImage: `url(${backend}/storage/${project.imgs[0]})`,
                     backgroundSize:'contain'
                      }}
                      alt=""
                    >
                        <Flex 
                        mih={100}
                    gap="xl"
                    justify="center"
                    align="flex-end"
                    direction="row"
                    wrap="nowrap" style={{height:"100%",width:'100%'}}>
                    <button
                      onClick={(e) => goToProject(project.id,project.title.replaceAll(" ",""))}
                      className="view-btn"
                      style={{padding:"10px 30px",  fontSize: "16px",marginBottom:"50px"}}
                    >
                      View Project
                    </button>
                    </Flex>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <></>

            )}
          </Container>
        </div>
      ):
      <div style={{ paddingTop: "140px" }}>

<MyLoader/>

      </div>

      }
    </>
  );
};

export default Projects;
