import {
  Container,
  Image,
  MediaQuery,
  Burger,
  Drawer,
 
  Box,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import logo from "../imgs/logo.png";
import { Flex } from "@mantine/core";
import { Link } from "react-router-dom";
import { HashLink, NavHashLink } from 'react-router-hash-link';
import { useState } from "react";

const Navbar = ({ links }) => {
//   const location = useLocation();
  const [opened, { toggle }] = useDisclosure(false);
  const [active, setActive]=useState(null)
  const label = opened ? "Close navigation" : "Open navigation";
  return (
    <>
      <MediaQuery smallerThan="md" styles={{ display: "none" }}>
        <div className="navbar-main">
          <Container>
            <Flex
              mih={50}
              gap="xl"
              justify="space-between"
              align="center"
              direction="row"
              wrap="nowrap"
            >
              <HashLink to="/#" smooth>
                <Image src={logo} alt="" py={10} width={75} />
              </HashLink>
              <Flex
                mih={50}
                gap="md"
                justify="center"
                align="center"
                direction="row"
                wrap="nowrap"
              >
                {links.map((link,index) => (
                 
                 
                   <HashLink 
                   key={index}
                   id={index}
                   smooth
                 onClick={(e)=>setActive(e.id)}
                   to={link.link}
                   className={`text-decoration-none nav-link ${
                     link.btn === 1 ? "primary-btn" : ""
                   }`}
                 >
                   {link.label}
                 </HashLink>
                
                 
                ))}
              </Flex>
            </Flex>
          </Container>
        </div>
      </MediaQuery>

      <MediaQuery largerThan="md" styles={{ display: "none" }}>
        <div className="navbar-main">
          <Container>
            <Flex
              mih={50}
              gap="xl"
              justify="space-between"
              align="center"
              direction="row"
              wrap="nowrap"
            >
              <HashLink to="/#" smooth>
                <Image src={logo} alt="" py={10} width={75} />
              </HashLink>
              <Burger
                opened={opened}
                onClick={toggle}
                aria-label={label}
                style={{ zIndex: "5" }}
              />
            </Flex>
          </Container>
        </div>
      </MediaQuery>
      <Drawer
        transitionProps={{
          transition: "rotate-left",
          duration: 150,
          timingFunction: "linear",
        }}

        opened={opened}
        onClose={toggle}
        style={{ backgroundColor: "red" }}
        size="100%"
        withCloseButton={false}
      >
        <Flex
          mih={50}
          gap="xl"
          justify="end"
          align="center"
          direction="row"
          wrap="nowrap"
        >
          <Burger
            opened={opened}
            py={10}
            onClick={toggle}
            aria-label={label}
            style={{ zIndex: "5" }}
          />
        </Flex>
        <Box style={{ height: "80vh" }}>
          <Flex
            mih={50}
            gap="md"
            justify="center"
            align="center"
            direction="column"
            wrap="wrap"
          >
            {links.map((link,index) => (
              <HashLink smooth
              key={index}
                to={link.link}
                className={`text-decoration-none ${
                  link.btn === 1 ? "primary-btn" : ""
                }`}

                onClick={toggle}
              >
                {link.label}
              </HashLink>
            ))}
          </Flex>
        </Box>
      </Drawer>
    </>
  );
};

export default Navbar;
