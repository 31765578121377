import { Button, Flex, Input, Space, Textarea } from "@mantine/core";
import { useState } from "react";
import { At, Send } from "tabler-icons-react";

const Form = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const [submiting, setSubmiting] = useState(false);
  const backend=process.env.REACT_APP_BACKEND_URL;

  function handleSubmiting(e){
    e.preventDefault();
    setSubmiting(true);
    fetch(backend+'/message-submit', {
        method: 'post',
        headers: {'Content-Type':'application/json'},
        body: {
         "firstname":firstName,
         "lastname":lastName,
         "email":email,
         "msg":msg
        }
       }).then((res)=>{
        console.log(res)

        setSubmiting(false);

       });


    }
  return (
    <form onSubmit={handleSubmiting} action="">
      <Flex gap={4}>
        <Input  placeholder="First Name" value={firstName} onChange={(e)=>setFirstName(e.target.value)} style={{ width: "50%" }} required />
        <Input placeholder="Last Name"value={lastName} onChange={(e)=>setLastName(e.target.value)} style={{ width: "50%" }} required />
      </Flex>
      <Space h={"10px"} />

      <Input placeholder="Email"value={email} onChange={(e)=>setEmail(e.target.value)} icon={<At size={14} strokeWidth={2} />}  required/>
      <Space h={"10px"} />
      <Textarea placeholder="Your Message" value={msg} onChange={(e)=>setMsg(e.target.value)}minRows={5}  required/>
      <Space h={"10px"} />

      <Button
      type="submit"
        className="primary-btn"
        rightIcon={<Send size={14} strokeWidth={2} />}
        color={"#9e5c66"}
        loading={submiting ? true : false}
        >
        Send
      </Button>
    </form>
  );
};

export default Form;
