import { Anchor, Container, Flex, Text } from "@mantine/core";
import useFetch from "../hooks/useFetch";

const Footer = () => {
  const { data: info } = useFetch("info");

  return (
    <div className="footer-div">
      <Container>
        <Flex
          mih={100}
          gap="md"
          justify="between"
          align="center"
          direction="row"
          wrap="nowrap"
        >
          <Text fz="sm" color={"white"}>
            &#169;2023 Solid Decore | Interior Design. All rights reserved
          </Text>
          {info && (
            <Text fz="sm" color={"white"}>
              Tel:{" "}
              <Anchor href={`tel:${info.phone}`} color="#9e7278e7">
                {info.phone}
              </Anchor>{" "}
              | Email:{" "}
              <Anchor href={`mailto:${info.email}`} color="#9e7278e7">
                {info.email}
              </Anchor>{" "}
              | Address: Amman,Jordan
            </Text>
          )}
        </Flex>
      </Container>
    </div>
  );
};

export default Footer;
