import {  Center, Image, Text, Title } from "@mantine/core";
import useFetch from "../hooks/useFetch";
import { Carousel } from "@mantine/carousel";
import parse from 'html-react-parser';
import Autoplay from 'embla-carousel-autoplay';
import { useRef } from "react";
import MyLoader from "./MyLoader";

const Clients = () => {
  const { data: clients } = useFetch("clients");
  const backend = process.env.REACT_APP_BACKEND_URL;
  const autoplay = useRef(Autoplay({ delay: 2000 }));

  return (
    <div id="clients"
   

    >
      <Title
        order={2}
        weight={300}
        align="center"
        style={{ paddingTop: "60px", letterSpacing: "4px" }}
      >
        OUR CLIENTS
      </Title>
      <hr className="my-underline" />
      {clients ?
      <Carousel
        withIndicators
        slideSize="25%"
        slideGap="md"
        loop
        align="start"
        slidesToScroll={1}
        // plugins={[autoplay.current]}
        // onMouseEnter={autoplay.current.stop}
        // onMouseLeave={autoplay.current.reset}
      >
       
          {clients.map((client,index) => (
            <Carousel.Slide key={index}>
              <Image width={150} mx="auto"  src={backend + "/storage/" + client.img} />
                <Title className="clients-title" align="center" order={3} weight={500}>
                {client.title}
                </Title>
                <Center >
           {/* <Text align="center" w={200}  weight={100}>
            {parse(client.review)}
           </Text> */}
           </Center>
            </Carousel.Slide>
          ))}
      </Carousel>

      :
      <MyLoader/>
      }
    </div>
  );
};

export default Clients;
