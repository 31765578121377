import { useState } from "react";
import useFetch from "../hooks/useFetch";
import { useParams } from "react-router-dom";
import { Carousel } from "@mantine/carousel";
import { Button, Container, Grid, Space, Text, Title } from "@mantine/core";
import Autoplay from "embla-carousel-autoplay";
import { useRef } from "react";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom/dist";
import { ArrowBadgeLeft } from "tabler-icons-react";
import MyLoader from "../components/MyLoader";

const ShowProject = () => {
  const { id } = useParams();
  const { data, isPending } = useFetch("project/" + id);
  const backend = process.env.REACT_APP_BACKEND_URL;
  const autoplay = useRef(Autoplay({ delay: 2000 }));
  const navigate = useNavigate();

  return (
    <>
      <Space h={"140px"} />
      <Container>
        <Carousel
          height={500}
          style={{ width: "100%" }}
          loop
          dragFree
         
          plugins={[autoplay.current]}
          onMouseEnter={autoplay.current.stop}
          onMouseLeave={autoplay.current.reset}
        >
          {data ?
            data.imgs.map((img, index) => (
              <Carousel.Slide key={index}>
                <img
                  src={backend + "/storage/" + img}
                 
                  style={{margin:"auto", display:"block", height: "100%" }}
                  alt=""
                />
              </Carousel.Slide>
            )):<MyLoader/>}
        </Carousel>
        <Space h={"40px"} />

        {data && (
          <Grid>
            <Grid.Col lg={6} md={6} sm={12}>
              <Title order={3} weight={300} style={{ letterSpacing: "4px" }}>
                {data.location}
              </Title>
              <Title mt={20} order={1} weight={700} className="primary-text">
                {data.title}
              </Title>
            </Grid.Col>
            <Grid.Col lg={6} md={6} sm={12}>
              <Text
                mt={0}
                pt={0}
                className="remove-padding"
                fz="xl"
                c="gray.7"
                fw={500}
              >
                {parse(data.desc)}
              </Text>
              <Button
                variant="outline"
                leftIcon={<ArrowBadgeLeft />}
                color="dark"
                radius="xl"
                onClick={(e) =>
                  navigate(
                    "/projects/" + data.category.id + "/" + data.category.title
                  )
                }
              >
                Back
              </Button>
            </Grid.Col>
          </Grid>
        )
      }
      </Container>
      <Space h={"80px"} />
    </>
  );
};

export default ShowProject;
