import { Container, Flex, Grid, Title } from "@mantine/core";
import useFetch from "../hooks/useFetch";
import { useNavigate } from "react-router-dom/dist";
import MyLoader from "../components/MyLoader";

const Portfolio = () => {
  const { data } = useFetch("categories");
  const navigate = useNavigate();
  const backend = process.env.REACT_APP_BACKEND_URL;
  function goToProjects(id, title) {
    navigate("/projects/" + id + "/" + title);
  }
  return (
    <>
      <Title
        order={2}
        weight={300}
        align="center"
        style={{ paddingTop: "140px", letterSpacing: "4px" }}
      >
        OUR PROJECTS
      </Title>
      <hr className="my-underline" />

      <Container size="md" style={{ paddingBottom: "140px" }}>
        <Grid grow>
          {data ?
            data.map((category, index) => (
              <Grid.Col
                key={index}
                lg={6}
                md={6}
                sm={12}
                className="category-card"
                style={{
                  backgroundImage: `url(${backend}/storage/${category.img})`,
                }}
              >
                <Container style={{ width: "100%", height: "100%" }}>
                  <Flex
                    style={{ height: "100%", width: "100%" }}
                    mih={100}
                    gap="xl"
                    justify=""
                    align="flex-end"
                    direction="row"
                    wrap="nowrap"
                  >
                    <Grid
                      style={{ width: "100%" }}
                      wrap="nowrap"
                      justify="space-between"
                      align="flex-end"
                    >
                      <Grid.Col span={6}>
                        <Title tt="uppercase" color="white" weight={500}>
                          {category.title}
                        </Title>
                      </Grid.Col>
                      <Grid.Col justify={"flex-end"} span={6}>
                        <button
                          style={{ padding: "5px 20px", fontSize: "16px" }}
                          onClick={(e) =>
                            goToProjects(
                              category.id,
                              category.title.replaceAll(" ", "")
                            )
                          }
                          className="view-btn"
                        >
                          View Projects
                        </button>
                      </Grid.Col>
                    </Grid>
                  </Flex>
                </Container>
              </Grid.Col>
            )):<MyLoader/>}
        </Grid>
      </Container>
    </>
  );
};

export default Portfolio;
