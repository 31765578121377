import { Center, Container, Flex, Grid, Text, Title } from "@mantine/core";
import useFetch from "../hooks/useFetch";
import parse from "html-react-parser";
import MyLoader from "./MyLoader";

const Services = () => {
  const { data, isPending } = useFetch("services");
  const backend = process.env.REACT_APP_BACKEND_URL;

  return (
    <div id="services"
   
    
    >
      <Title
        order={2}
        weight={300}
        align="center"
        style={{ paddingTop: "100px", letterSpacing: "4px" }}
      >
        OUR SERVICES
      </Title>
      <hr className="my-underline" />
      <Grid grow>
        {data ?
          data.map((service,index) => (
            <Grid.Col
            key={index}
              className="service-col"
              style={{
                backgroundImage: `url(${backend}/storage/${service.img})`,
              }}
              lg={6}
              md={6}
              sm={12}
            >
              <Flex justify="center" align="center" style={{ height: "100%" }}>
                <div className="service-inner-card">
                  <Container size="xs">
                    <Title
                      tt="uppercase"
                      order={5}
                      weight={700}
                      color="white"
                      align="center"
                      pt={30}
                      pb={10}
                      style={{ letterSpacing: "5px", wordWrap: "break-word" }}
                    >
                      {service.title.split(" ").map((word)=>(
                        <>
                        {word}
                        <br/>
                        </>
                      ))}
                    </Title>

                    <Text align="center" weight={200} size={"18px"} color="white">
                      {parse(service.desc)}
                    </Text>
                  </Container>
                </div>
              </Flex>
            </Grid.Col>
          ))
        :

      <MyLoader/>

        }
      </Grid>
    </div>
  );
};

export default Services;
