import {
    ActionIcon,
  Anchor,
  Box,
  Container,
  Flex,
  Grid,
  Space,
  Text,
  Title,
} from "@mantine/core";
import useFetch from "../hooks/useFetch";
import { BrandFacebook, BrandInstagram } from "tabler-icons-react";
import { Link } from "react-router-dom";
import Form from "../components/Form";

const Contact = () => {
  const { data: info } = useFetch("info");

  return (
    <>
      {info && (
        <div>
          <Box bg={"red.1"}>
            <Space h={"140px"} />

            <Title align="center" c={"gray.8"} size={"50px"}>
              Book your free Consultation
            </Title>
            <Space h={"30px"} />

            <Text align="center" weight={200}>
              or call us now{" "}
              <Anchor fz={"20px"} href={`tel:${info.phone}`} color="blue.5">
                {info.phone}
              </Anchor>
            </Text>
            <Space h={"70px"} />
          </Box>
          <Space h={"50px"} />

          <Container>
            <Grid>
              <Grid.Col lg={4} md={4} sm={12}>
                <Title order={3} weight={500} >Address</Title>
                <Text>{info.address}</Text>
                <Space h={"40px"} />
                <Title order={3} weight={500} >Phone</Title>
                <Text>{info.phone}</Text>
                <Space h={"40px"} />
                <Title order={3} weight={500} >Email</Title>
                <Text>{info.email}</Text>
                <Space h={"40px"} />
                <Title order={3} weight={500} >Social Media</Title>
              <Flex>
              <Link to={info.facebook_link} target="_blank">
                <ActionIcon color="dark" variant="transparent">

                <BrandFacebook  strokeWidth={1}  />

                </ActionIcon>
                </Link>
                <Link to={info.insta_link} target="_blank">
                <ActionIcon color="dark" variant="transparent">

                <BrandInstagram  strokeWidth={1}  />

                </ActionIcon>
                </Link>
                </Flex>
              </Grid.Col>
              <Grid.Col lg={2} md={2} sm={0}>
            </Grid.Col>
              <Grid.Col lg={6} md={6} sm={12}>
                <Form/>
            </Grid.Col>
           
            </Grid>

          </Container>
          <Space h={"90px"} />
        </div>
      )}
    </>
  );
};

export default Contact;
