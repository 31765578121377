import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import { useState } from "react";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Portfolio from "./pages/Portfolio";
import Projects from "./pages/Projects";
import ShowProject from "./pages/ShowProject";
import About from "./pages/About";
import Contact from "./pages/Contact";

function App() {
  const [links, setLinks] = useState([
    { link: "/#services", label: "Our Services", btn: 0, hash: 1 },
    { link: "/portfolio", label: "Portfolio", btn: 0, hash: 0 },
    { link: "/#clients", label: "Clients", btn: 0, hash: 1 },
    { link: "/about", label: "About", btn: 0, hash: 0 },
    { link: "/contact", label: "CONTACT US", btn: 1, hash: 0 },
  ]);
  return (
    <div className="App">
      <header className="App-header">
        <div className="content">
          <Navbar links={links} />
          <Routes>
            <Route exact path="/" element={<Home />}></Route>
            <Route exact path="/portfolio" element={<Portfolio />}></Route>
            <Route exact path="/about" element={<About />}></Route>
            <Route exact path="/contact" element={<Contact />}></Route>

            <Route
              exact
              path="/projects/:id/:title"
              element={<Projects />}
            ></Route>
            <Route
              exact
              path="/project/:id/:title"
              element={<ShowProject />}
            ></Route>
          </Routes>
          <div style={{ height: "90px" }}></div>
          <Footer />
        </div>
      </header>
    </div>
  );
}

export default App;
