import { Container, Space, Text, Title } from "@mantine/core";
import useFetch from "../hooks/useFetch";
import parse from "html-react-parser";

const About = () => {
  const { data:about, isPending } = useFetch("page/about");
  const { data:info, } = useFetch("info");

  const backend = process.env.REACT_APP_BACKEND_URL;

  return (
    <>
      <Title
        order={2}
        weight={300}
        align="center"
        style={{ paddingTop: "140px", letterSpacing: "4px" }}
      >
        WHO ARE WE
      </Title>
      <hr className="my-underline" />
      <Container>
        {about && (
          <>
            <Text
              mt={0}
              pt={0}
              className="remove-padding"
              fz="xl"
              c="gray.7"
              fw={300}
            >
              {parse(about.paragraph)}
            </Text>

            <Title
              order={2}
              weight={300}
              align="center"
              style={{ paddingTop: "60px", letterSpacing: "4px" }}
            >
              OUR LOCATION
            </Title>
            <hr className="my-underline" />

            <iframe title="map"
              className="mt-3"
              height={"400px"}
              width={"100%"}
              style={{border:"none"}}
              src={`//maps.google.com/maps?q=${info.lat},${info.lng}&z=15&output=embed`}
              allow="geolocation"
            ></iframe>
                  <Space h={"80px"} />

          </>
        )}
      </Container>
    </>
  );
};

export default About;
