import { Center, Loader } from '@mantine/core';

const MyLoader = () => {
    return ( 

        <Center maw={400} h={100} mx="auto">
        <Loader color="red" className="center" />
        </Center>
        
     );
}
 
export default MyLoader;