import useFetch from "../hooks/useFetch";
import Services from "../components/Services";
import Clients from "../components/Clients";
import { Loader } from '@mantine/core';

const Home = () => {

  const {data ,isPending }=useFetch('page/home');
  const backend=process.env.REACT_APP_BACKEND_URL;

  return (
    <>
    { data ?
    <div
      className="header-div"
      style={{ backgroundImage: `url(${backend}/storage/${data.img})`}}
    ></div>: <Loader color="red" />

    }
<Services/>
<Clients/>
</>

  );
};

export default Home;
